/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */

import {getScreenHeight, isMobile} from './helper.js';
import $ from 'jquery';

const toggleMenu = () => {
	let button = document.getElementById('toggle'),
		container = document.getElementById('site-navigation'),
		html = document.querySelector('html');

		if (button && container) {
			button.addEventListener('click', () => {

				if(button.classList.contains('open')) {
					container.classList.remove('open');
					button.classList.remove('open');
					html.style.overflowY = 'auto';		
					return;
				}

				container.classList.add('open');
				button.classList.add('open');

				if (getScreenHeight() <= 300) {
					html.style.overflowY = 'hidden';
				} else {
					html.style.overflowY = 'auto';					
				}
	
			}) 
		}
} 
toggleMenu();

const closeMenu = () => {
	let links = document.querySelectorAll('.main-navigation a'),
		nav = document.getElementById('site-navigation'),
		toggle = document.getElementById('toggle');

	links.forEach( (e) => {
		e.addEventListener('click', () => {
			nav.classList.remove('open');
			toggle.classList.remove('open');
		})
	})
}


const addToggleMenu = () => {
	let toggle = document.getElementById('toggle'),
		header = document.getElementById('masthead');

	toggle.addEventListener('click', () => {
		if(header.classList.contains('toggled-menu')) {
			header.classList.remove('toggled-menu')
			return
		}
		header.classList.add('toggled-menu');
	})
	
}

if (isMobile()) {
	closeMenu();
	addToggleMenu();
}

window.onresize = () => {
	if (isMobile()) {
		closeMenu();
		addToggleMenu();
	}
};